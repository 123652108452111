import cx from 'classnames';
import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ControlProvider } from './ControlProvider';
import { FeedbackMessage } from './FeedbackMessage';
import { Label } from './Label';

interface InputProps extends ComponentPropsWithoutRef<'div'> {
  id: string;
  label?: ReactNode;
  children: ReactElement;
  error?: string | string[] | null;
  info?: string | null;
  labelClassName?: string;
  required?: boolean;
}

export const Control = ({
  id,
  className,
  labelClassName,
  label,
  children,
  error,
  info,
  required = false,
  ...rest
}: InputProps) => {
  return (
    <ControlProvider
      id={id}
      // TODO: support array of errors in ControlProvider
      error={Array.isArray(error) ? error.join(', ') : error}
    >
      <div className={twMerge('flex flex-col w-full mb-4', className)} {...rest}>
        <div className="flex flex-row items-center justify-between">
          {label ? (
            <Label
              className={cx(labelClassName)}
              labelClassName={cx({
                'text-red-500': error,
              })}
            >
              {label}
            </Label>
          ) : (
            <div></div>
          )}
          {!required ? <div className="text-xxs-medium uppercase leading-6 text-gray-500">Optional</div> : null}
        </div>

        {children}
        {error ? (
          <FeedbackMessage variant="error" className="mt-1">
            {error}
          </FeedbackMessage>
        ) : null}
        {info ? (
          <FeedbackMessage withIcon variant="info" className={'mt-1'}>
            {info}
          </FeedbackMessage>
        ) : null}
      </div>
    </ControlProvider>
  );
};
